import React from "react"
import "./Button.scss"
import Quark from "../icons/button-quark.svg"

function Button(props) {
  return (
    <button onClick={props.clicker} className="quenchButton">
      <Quark className="left" />
      <Quark className="right" />
      {props.children}
    </button>
  )
}
export default Button
