import React from "react"
import "./AboutFace.scss"

function AboutFace(props) {
  return (
    <section
      className={`expand aboutFace ${props.reverse ? "reverse" : ""} ${
        props.background
      } ${props.className}`}
    >
      <div className="text">
        <h2>
          {props.h1} <span>{props.h2}</span>
        </h2>
        <p>{props.copy}</p>
      </div>
      <div className="image">
        <div className="plax">
          <img src={props.brewerback} alt="" />
        </div>
        <div className="imageContainer">
          <img src={props.brewer} alt="Quench Inspires" />
        </div>
      </div>
    </section>
  )
}
export default AboutFace
