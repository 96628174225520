import React from "react"
import "./Hero.scss"
import Header from "./Header"
import Button from "./Button"
import FakeBackground from "../components/FakeBackground"

class Hero extends React.Component {
  render() {
    console.log(this.props.clicker)
    return (
      <div className={this.props.className}>
        <FakeBackground className="heroPlax" />
        <Header />

        <div className="container">
          <div className="hero">
            <h2>
              <span>All together</span> Satisfying.
            </h2>
            <p>
              At Quench Global Brands, our vision is simple: create a really
              cool, innovative company that people love by building things that
              bring people together.
            </p>
            <Button clicker={this.props.clicker}>Contact Us</Button>
          </div>
        </div>
      </div>
    )
  }
}

export default Hero
