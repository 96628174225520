import React from "react"
import "./NavButton.scss"
import NavIcon from "../icons/nav-button.svg"

class NavButton extends React.Component {
  render() {
    return (
      <button
        className={`navButton ${this.props.className} ${
          this.props.isShowing ? "showing" : "hidden"
        }`}
        onClick={this.props.clicker}
      >
        <NavIcon />
      </button>
    )
  }
}

export default NavButton
