import React from "react"
import "./Contact.scss"
import Button from "./Button"
import quarkBack from "../images/quark-background.svg"
import qs from "qs"
import axios from 'axios'

class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      message: "",
      loading:false
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    console.log(this.state);

    axios({
      method: "POST",
      url: "http://quench.seedougrun.com/api/contact/index.php",
      data: qs.stringify({
        name: this.state.name,
        email: this.state.email,
        message: this.state.message
      }),
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    }).then(response => {
      console.log(response);
      
      if (response.status === 200) {
        alert("Message Sent.")
        this.resetForm()
      } else if (response.status === 500) {
        alert("Message failed to send. Please try again soon.")
      }
    })
    // alert("Form Sent.")
  }

  resetForm() {
    this.setState({ name: "", email: "", message: "" })
  }

  render() {
    return (
      <div className="container">
        <div
          className="plaxBack"
          style={{ backgroundImage: `url(${quarkBack}` }}
        ></div>
        <section className={`contactForm`}>
          <h2>
            We're Here<span>for you always</span>
          </h2>
          <p>Reach out and let us know how we can&nbsp;help.</p>
          <form
            id="contact-form"
            onSubmit={this.handleSubmit.bind(this)}
            method="POST"
          >
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={this.state.name}
                onChange={this.onNameChange.bind(this)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                aria-describedby="emailHelp"
                value={this.state.email}
                onChange={this.onEmailChange.bind(this)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                className="form-control"
                rows="5"
                id="message"
                value={this.state.message}
                onChange={this.onMessageChange.bind(this)}
              />
            </div>
            <Button>Submit</Button>
          </form>
        </section>
      </div>
    )
  }
  onNameChange(event) {
    this.setState({ name: event.target.value })
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value })
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value })
  }
}
export default Contact
