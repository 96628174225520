import React from "react"
import "./Header.scss"
import Logo from "../icons/quench-logo.svg"

class Header extends React.Component {
  render() {
    return (
      <header className="quenchHeader">
        <h1>
          <Logo className={`quenchLogo`} />
        </h1>
      </header>
    )
  }
}

export default Header
