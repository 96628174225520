import React from "react"
import ReactFullpage from "@fullpage/react-fullpage"
import Hero from "../components/Hero"
import "../styles/styles.scss"
import { Helmet } from "react-helmet"
import NavButton from "../components/NavButton"
import AboutFace from "../components/AboutFace"
import Contact from "../components/Contact"
import back1 from "../images/barrels.jpg"
import guy1 from "../images/brewer_1.png"
import back2 from "../images/bottles.jpg"
import guy2 from "../images/brewer_2.png"
import back3 from "../images/tanks.jpg"
import guy3 from "../images/brewer_3.png"

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showUp: false,
      showDown: true,
      currentPage: 0,
      fullpages: [
        {
          text: "Section 1",
        },
        {
          text: "Section 2",
        },
        {
          text: "Section 3",
        },
      ],
    }
  }

  onLeave(origin, destination, direction) {
    console.log("onLeave", { origin, destination, direction })
    let topClass = destination.isFirst ? false : true
    this.setState({ showUp: topClass })
    let bottomClass = destination.isLast ? false : true
    this.setState({ showDown: bottomClass })
    this.setState({ currentPage: destination.index })
  }

  moveSectionDown() {
    window.fullpage_api.moveSectionDown()
  }
  moveSectionUp() {
    window.fullpage_api.moveSectionUp()
  }
  moveToContact() {
    window.fullpage_api.moveTo(5)
  }
  render() {
    return (
      <div className="App">
        <Helmet title="Quench Global Brands" defer={false}>
          <link
            href="https://fonts.googleapis.com/css?family=Prompt:200,500,900&display=swap"
            rel="stylesheet"
          ></link>
          <link
            rel="stylesheet"
            href="https://use.typekit.net/nlk3dzc.css"
          ></link>
        </Helmet>

        <ReactFullpage
          //fullpage options
          licenseKey={"YOUR_KEY_HERE"}
          scrollingSpeed={1000} /* Options here */
          onLeave={this.onLeave.bind(this)}
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                <div className="section hero">
                  <Hero
                    className={this.state.currentPage > 0 ? "above" : "below"}
                    clicker={this.moveToContact}
                  />
                </div>
                <div className="section">
                  <AboutFace
                    className={this.state.currentPage > 1 ? "above" : "below"}
                    background="whiteish"
                    h1="We Inspire"
                    h2={`those "ahh!" moments.`}
                    copy={`We create unbreakable partnerships by forming, acquiring and building mutually satisfying businesses. By developing exciting brands, products, services and solutions. And by taking impeccable care of our customers, associates and partners all along the way.`}
                    brewer={guy1}
                    brewerback={back1}
                  />
                </div>
                <div className="section">
                  <AboutFace
                    className={this.state.currentPage > 2 ? "above" : "below"}
                    background="white"
                    reverse={true}
                    h1="We Are"
                    h2={`losing, if you aren’t winning.`}
                    copy={`The foundation of a thriving business should be partnerships that benefit all stakeholders. We live this value by always putting more into our relationships than we expect to get out. We firmly believe that by focusing 100% on the success of our associates, supplier partners and customers, the success of our company is guaranteed.`}
                    brewer={guy2}
                    brewerback={back2}
                  />
                </div>
                <div className="section">
                  <AboutFace
                    className={this.state.currentPage > 3 ? "above" : "below"}
                    background="whiteish"
                    h1="We Say"
                    h2={`waste not, want more.`}
                    copy={`Our approach is results driven. When we see ways of helping our partners achieve a goal, a milestone or a dream, we take action. We move swiftly, deliberately and methodically to put efficient and effective processes into practice—actively satisfying your needs.`}
                    brewer={guy3}
                    brewerback={back3}
                  />
                </div>
                <div className="section contact">
                  <Contact />
                  <p className="copyright">© 2020 Quench Global Brands</p>
                </div>
              </ReactFullpage.Wrapper>
            )
          }}
        />
        <NavButton
          className="moveUp"
          isShowing={this.state.showUp}
          clicker={this.moveSectionUp}
        />
        <NavButton
          className="moveDown"
          isShowing={this.state.showDown}
          clicker={this.moveSectionDown}
        />
      </div>
    )
  }
}

export default App
